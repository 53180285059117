@import '~src/scss/index.scss';
.page {
  max-width: 550rem;
  min-height: calc(100vh - 132rem);
  margin: 0 auto;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@include mobile {


}
