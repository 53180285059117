@import '~src/scss/index.scss';
.bg {
    position: relative;
    z-index: 0;
}

.bg::before {
    position: absolute;
    top: -112rem;
    left: 0;
    z-index: -1;
    width: 100%;
    min-height: 1106rem;
    background-image: url('/images/transparent-bg.webp');
    background-position: center;
    content: '';
}
