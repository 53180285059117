@mixin basic-transition($property: all) {
  transition: $property 0.23s $ease-in-out-quadratic;
}

@mixin standard-border {
  border: 1rem solid $gray-20;
}

@mixin animate-grow {
  animation-name: grow;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  animation-fill-mode: backwards;
}

@keyframes grow {

  0% {
    transform: translateY(-50%) scale(1, 0);
    visibility: hidden;
    opacity: 0;
  }

  100% {
    transform: translateY(0%) scale(1, 1);
    opacity: 1;
  }
}

@mixin mouse-focus {

  :global(.js-focus-visible) &:focus:not(:global(.focus-visible)) {
    @content;
  }
}

@mixin keyboard-focus {

  :global(.js-focus-visible) &:global(.focus-visible):focus {
    @content;
  }
}

@mixin no-scrollbar {

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

@mixin placeholder {
  &::placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}

@mixin unselectable-text {
  -webkit-user-select: none;

  /* Safari */
  -moz-user-select: none;

  /* Firefox */
  -ms-user-select: none;

  /* IE10+/Edge */
  user-select: none;

  /* Standard */
}

@mixin custom-scrollbar() {

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-60;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}
